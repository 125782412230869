<template>
    <b-card no-body header-bg-variant="bluenavy" header="Notifiche" header-text-variant="white" header-tag="h2"
        border-variant="bluenavy">
        <b-card-body class="p-0">
            <div class="d-flex flex-row">
                <!--begin::Aside-->
                <div class="flex-row-auto offcanvas-mobile w-200px w-xxl-275px" id="kt_inbox_aside">
                    <!--begin::Card-->
                    <div class="card card-custom card-stretch">
                        <!--begin::perfect-scrollbar-->
                        <perfect-scrollbar class="scroll" style="max-height: 70vh; position: relative;">
                            <!--begin::Body-->
                            <div class="card-body px-5">
                                <!--begin::Navigations-->
                                <div
                                    class="navi navi-hover navi-link-rounded navi-bold navi-icon-center navi-light-icon">
                                    <!--begin::Item-->
                                    <div class="navi-item my-2">
                                        <a href="#" class="navi-link" @click.prevent="toggleFilters('Inbox');">
                                            <span class="navi-icon mr-4">
                                                <span class="svg-icon svg-icon-lg svg-icon-primary">
                                                    <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-heart.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"></rect>
                                                            <path
                                                                d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,13 C19,13.5522847 18.5522847,14 18,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M13.8,4 C13.1562,4 12.4033,4.72985286 12,5.2 C11.5967,4.72985286 10.8438,4 10.2,4 C9.0604,4 8.4,4.88887193 8.4,6.02016349 C8.4,7.27338783 9.6,8.6 12,10 C14.4,8.6 15.6,7.3 15.6,6.1 C15.6,4.96870845 14.9396,4 13.8,4 Z"
                                                                fill="#000000" opacity="0.3"></path>
                                                            <path
                                                                d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z"
                                                                fill="#000000"></path>
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </span>
                                            <span class="navi-text font-weight-bolder font-size-lg">In Entrata</span>
                                            <span
                                                v-if="typeof unreadNotifications['Inbox'] !== 'undefined' && unreadNotifications['Inbox']"
                                                class="navi-label">
                                                <span
                                                    class="label label-rounded label-light-primary font-weight-bolder">{{
                                                        unreadNotifications['Inbox'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="navi-item my-2 ml-2">
                                        <a href="#" class="navi-link" @click.prevent="toggleFilters('Special');">
                                            <span class="navi-icon mr-4">
                                                <span class="svg-icon svg-icon-lg svg-icon-warning">
                                                    <!--begin::Svg Icon | path:assets/media/svg/icons/General/Half-star.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path
                                                                d="M12,4.25932872 C12.1488635,4.25921584 12.3000368,4.29247316 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 L12,4.25932872 Z"
                                                                fill="#000000" opacity="0.3"></path>
                                                            <path
                                                                d="M12,4.25932872 L12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.277344,4.464261 11.6315987,4.25960807 12,4.25932872 Z"
                                                                fill="#000000"></path>
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </span>
                                            <span class="navi-text font-weight-bolder font-size-lg">Speciali</span>
                                            <span
                                                v-if="typeof unreadNotifications['Speciali'] !== 'undefined' && unreadNotifications['Speciali']"
                                                class="navi-label">
                                                <span
                                                    class="label label-rounded label-light-warning font-weight-bolder">{{
                                                        unreadNotifications['Speciali'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="navi-item my-2 ml-2">
                                        <a href="#" class="navi-link" @click.prevent="toggleFilters('Important');">
                                            <span class="navi-icon mr-4">
                                                <span class="svg-icon svg-icon-lg svg-icon-warning">
                                                    <!--begin::Svg Icon | path:assets/media/svg/icons/General/Settings#3.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <rect fill="#000000" opacity="0.3" x="2" y="6" width="21"
                                                                height="12" rx="6" />
                                                            <circle fill="#000000" cx="17" cy="12" r="4" />
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </span>
                                            <span class="navi-text font-weight-bolder font-size-lg">Importanti</span>
                                            <span
                                                v-if="typeof unreadNotifications['Importanti'] !== 'undefined' && unreadNotifications['Importanti']"
                                                class="navi-label">
                                                <span
                                                    class="label label-rounded label-light-warning font-weight-bolder">{{
                                                        unreadNotifications['Importanti'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Separator-->
                                    <div class="navi-item my-5"></div>
                                    <!--end::Separator-->
                                    <!--begin::Item-->
                                    <div class="navi-item my-2 ml-2">
                                        <a href="#" class="navi-link" @click.prevent="toggleFilters('Avvisi');">
                                            <span class="navi-icon mr-4">
                                                <i class="flaticon2-exclamation text-danger"></i>
                                            </span>
                                            <span class="navi-text">Avvisi</span>
                                            <span
                                                v-if="typeof unreadNotifications['Avvisi'] !== 'undefined' && unreadNotifications['Avvisi']"
                                                class="navi-label">
                                                <span
                                                    class="label label-rounded label-light-danger font-weight-bolder">{{
                                                        unreadNotifications['Avvisi'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="navi-item my-2 ml-2">
                                        <a href="#" class="navi-link" @click.prevent="toggleFilters('Affiliazioni');">
                                            <span class="navi-icon mr-4">
                                                <i class="fa fa-handshake text-info"></i>
                                            </span>
                                            <span class="navi-text">Affiliazioni</span>
                                            <span
                                                v-if="typeof unreadNotifications['Affiliazioni'] !== 'undefined' && unreadNotifications['Affiliazioni']"
                                                class="navi-label">
                                                <span class="label label-rounded label-light-info font-weight-bolder">{{
                                                    unreadNotifications['Affiliazioni'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="navi-item my-2 ml-2">
                                        <a href="#" class="navi-link" @click.prevent="toggleFilters('Sodalizi');">
                                            <span class="navi-icon mr-4">
                                                <i class="fas fa-share-alt text-primary"></i>
                                            </span>
                                            <span class="navi-text">Sodalizi</span>
                                            <span
                                                v-if="typeof unreadNotifications['Sodalizi'] !== 'undefined' && unreadNotifications['Sodalizi']"
                                                class="navi-label">
                                                <span
                                                    class="label label-rounded label-light-primary font-weight-bolder">{{
                                                        unreadNotifications['Sodalizi'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="navi-item my-2 ml-2">
                                        <a href="#" class="navi-link" @click.prevent="toggleFilters('Tesserati');">
                                            <span class="navi-icon mr-4">
                                                <i class="fas fa-id-card text-success"></i>
                                            </span>
                                            <span class="navi-text">Tesserati</span>
                                            <span
                                                v-if="typeof unreadNotifications['Tesserati'] !== 'undefined' && unreadNotifications['Tesserati']"
                                                class="navi-label">
                                                <span
                                                    class="label label-rounded label-light-success font-weight-bolder">{{
                                                        unreadNotifications['Tesserati'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div v-if="$store.getters.currentUser.usertype !== 'Sodalizio'"
                                        class="navi-item my-2 ml-2">
                                        <a href="#" class="navi-link"
                                            @click.prevent="toggleFilters('Credito Comitati');">
                                            <span class="navi-icon mr-4">
                                                <span class="svg-icon svg-icon-lg svg-icon-warning">
                                                    <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Euro.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M4.3618034,10.2763932 L4.8618034,9.2763932 C4.94649941,9.10700119 5.11963097,9 5.30901699,9 L15.190983,9 C15.4671254,9 15.690983,9.22385763 15.690983,9.5 C15.690983,9.57762255 15.6729105,9.65417908 15.6381966,9.7236068 L15.1381966,10.7236068 C15.0535006,10.8929988 14.880369,11 14.690983,11 L4.80901699,11 C4.53287462,11 4.30901699,10.7761424 4.30901699,10.5 C4.30901699,10.4223775 4.32708954,10.3458209 4.3618034,10.2763932 Z M14.6381966,13.7236068 L14.1381966,14.7236068 C14.0535006,14.8929988 13.880369,15 13.690983,15 L4.80901699,15 C4.53287462,15 4.30901699,14.7761424 4.30901699,14.5 C4.30901699,14.4223775 4.32708954,14.3458209 4.3618034,14.2763932 L4.8618034,13.2763932 C4.94649941,13.1070012 5.11963097,13 5.30901699,13 L14.190983,13 C14.4671254,13 14.690983,13.2238576 14.690983,13.5 C14.690983,13.5776225 14.6729105,13.6541791 14.6381966,13.7236068 Z"
                                                                fill="#000000" opacity="0.3" />
                                                            <path
                                                                d="M17.369,7.618 C16.976998,7.08599734 16.4660031,6.69750122 15.836,6.4525 C15.2059968,6.20749878 14.590003,6.085 13.988,6.085 C13.2179962,6.085 12.5180032,6.2249986 11.888,6.505 C11.2579969,6.7850014 10.7155023,7.16999755 10.2605,7.66 C9.80549773,8.15000245 9.45550123,8.72399671 9.2105,9.382 C8.96549878,10.0400033 8.843,10.7539961 8.843,11.524 C8.843,12.3360041 8.96199881,13.0779966 9.2,13.75 C9.43800119,14.4220034 9.7774978,14.9994976 10.2185,15.4825 C10.6595022,15.9655024 11.1879969,16.3399987 11.804,16.606 C12.4200031,16.8720013 13.1129962,17.005 13.883,17.005 C14.681004,17.005 15.3879969,16.8475016 16.004,16.5325 C16.6200031,16.2174984 17.1169981,15.8010026 17.495,15.283 L19.616,16.774 C18.9579967,17.6000041 18.1530048,18.2404977 17.201,18.6955 C16.2489952,19.1505023 15.1360064,19.378 13.862,19.378 C12.6999942,19.378 11.6325049,19.1855019 10.6595,18.8005 C9.68649514,18.4154981 8.8500035,17.8765035 8.15,17.1835 C7.4499965,16.4904965 6.90400196,15.6645048 6.512,14.7055 C6.11999804,13.7464952 5.924,12.6860058 5.924,11.524 C5.924,10.333994 6.13049794,9.25950479 6.5435,8.3005 C6.95650207,7.34149521 7.5234964,6.52600336 8.2445,5.854 C8.96550361,5.18199664 9.8159951,4.66400182 10.796,4.3 C11.7760049,3.93599818 12.8399943,3.754 13.988,3.754 C14.4640024,3.754 14.9609974,3.79949954 15.479,3.8905 C15.9970026,3.98150045 16.4939976,4.12149906 16.97,4.3105 C17.4460024,4.49950095 17.8939979,4.7339986 18.314,5.014 C18.7340021,5.2940014 19.0909985,5.62999804 19.385,6.022 L17.369,7.618 Z"
                                                                fill="#000000" />
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </span>
                                            <span class="navi-text">Credito Comitati</span>
                                            <span
                                                v-if="typeof unreadNotifications['Credito Comitati'] !== 'undefined' && unreadNotifications['Credito Comitati']"
                                                class="navi-label">
                                                <span
                                                    class="label label-rounded label-light-warning font-weight-bolder">{{
                                                        unreadNotifications['Credito Comitati'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="navi-item my-2 ml-2">
                                        <a href="#" class="navi-link"
                                            @click.prevent="toggleFilters('Documenti Sodalizi');">
                                            <span class="navi-icon mr-4">
                                                <i class="far fa-folder-open text-primary"></i>
                                            </span>
                                            <span class="navi-text">Documenti Sodalizi</span>
                                            <span
                                                v-if="typeof unreadNotifications['Documenti Sodalizi'] !== 'undefined' && unreadNotifications['Documenti Sodalizi']"
                                                class="navi-label">
                                                <span
                                                    class="label label-rounded label-light-primary font-weight-bolder">{{
                                                        unreadNotifications['Documenti Sodalizi'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="navi-item my-2 ml-2">
                                        <a href="#" class="navi-link"
                                            @click.prevent="toggleFilters('Certificati Sodalizi');">
                                            <span class="navi-icon mr-4">
                                                <i class="far fa-file-alt text-primary"></i>
                                            </span>
                                            <span class="navi-text">Certificati Sodalizi</span>
                                            <span
                                                v-if="typeof unreadNotifications['Certificati Sodalizi'] !== 'undefined' && unreadNotifications['Certificati Sodalizi']"
                                                class="navi-label">
                                                <span
                                                    class="label label-rounded label-light-primary font-weight-bolder">{{
                                                        unreadNotifications['Certificati Sodalizi'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="navi-item my-2 ml-2">
                                        <a href="#" class="navi-link" @click.prevent="toggleFilters('Attività');">
                                            <span class="navi-icon mr-4">
                                                <i class="fas fa-dumbbell text-info"></i>
                                            </span>
                                            <span class="navi-text">Attività</span>
                                            <span
                                                v-if="typeof unreadNotifications['Attività'] !== 'undefined' && unreadNotifications['Attività']"
                                                class="navi-label">
                                                <span class="label label-rounded label-light-info font-weight-bolder">{{
                                                    unreadNotifications['Attività'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="navi-item my-2 ml-2">
                                        <a href="#" class="navi-link"
                                            @click.prevent="toggleFilters('Documenti e Comunicazioni');">
                                            <span class="navi-icon mr-4">
                                                <i class="fas fa-file-signature text-primary"></i>
                                            </span>
                                            <span class="navi-text">Comunicazioni e Documenti utili</span>
                                            <span
                                                v-if="typeof unreadNotifications['Documenti e Comunicazioni'] !== 'undefined' && unreadNotifications['Documenti e Comunicazioni']"
                                                class="navi-label">
                                                <span
                                                    class="label label-rounded label-light-primary font-weight-bolder">{{
                                                        unreadNotifications['Documenti e Comunicazioni'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Separator-->
                                    <div class="navi-item my-5"></div>
                                    <!--end::Separator-->
                                    <!--begin::Item-->
                                    <div class="navi-item my-2">
                                        <a href="#" class="navi-link" @click.prevent="toggleFilters('Trashed');">
                                            <span class="navi-icon mr-4">
                                                <span class="svg-icon svg-icon-lg svg-icon-danger">
                                                    <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"></rect>
                                                            <path
                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                fill="#000000" fill-rule="nonzero"></path>
                                                            <path
                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                fill="#000000" opacity="0.3"></path>
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </span>
                                            <span class="navi-text font-weight-bolder font-size-lg">Cestino</span>
                                            <span
                                                v-if="typeof unreadNotifications['Trashed'] !== 'undefined' && unreadNotifications['Trashed']"
                                                class="navi-label">
                                                <span
                                                    class="label label-rounded label-light-danger font-weight-bolder">{{
                                                        unreadNotifications['Trashed'] }}</span>
                                            </span>
                                        </a>
                                    </div>
                                    <!--end::Item-->
                                </div>
                                <!--end::Navigations-->
                            </div>
                            <!--end::Body-->
                        </perfect-scrollbar>
                        <!--end::perfect-scrollbar-->
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Aside-->

                <!--begin::List-->
                <div class="flex-row-fluid d-block" id="kt_inbox_list">
                    <!--begin::Card-->
                    <div class="card card-custom card-stretch">
                        <!--begin::Header-->
                        <div v-show="viewType === 'list'"
                            class="card-header row row-marginless align-items-center flex-wrap py-2 mx-0 h-auto border-0 bg-secondary">
                            <!--begin::Toolbar-->
                            <div
                                class="col-12 col-sm-6 col-xxl-4 order-2 order-xxl-1 d-flex flex-wrap align-items-center">
                                <div class="d-flex align-items-center mr-1 my-2">
                                    <label v-if="messagestotal" data-inbox="group-select"
                                        class="checkbox checkbox-inline checkbox-primary mr-3"
                                        @click.prevent="toggleMainChecked();">
                                        <input type="checkbox" :checked="mainChecked" />
                                        <span class="symbol-label"></span>
                                    </label>
                                    <b-dropdown v-if="messagestotal" size="sm" variant="link"
                                        toggle-class="custom-v-dropdown btn btn-default btn-icon btn-light-primary btn-sm mr-1 no-dropdown-icon">
                                        <template v-slot:button-content>
                                            <i class="ki ki-bold-arrow-down icon-1x"></i>
                                        </template>
                                        <!--begin::Navigation-->
                                        <div class="navi navi-hover min-w-md-250px">
                                            <b-dropdown-text
                                                v-for="(notificationCheckboxType, index) in notificationsCheckedTypes"
                                                :key="index" tag="div"
                                                :class="{ 'navi-item': notificationsCheckedType !== notificationCheckboxType, 'navi-item-hover': notificationsCheckedType === notificationCheckboxType }">
                                                <a href="#" class="navi-link"
                                                    @click.prevent="selectNotifications(notificationCheckboxType);">
                                                    <i v-if="notificationsCheckedType === notificationCheckboxType"
                                                        class="fas fa-check text-success mr-2"></i>
                                                    <span class="navi-text">{{ notificationCheckboxType }}</span>
                                                </a>
                                            </b-dropdown-text>
                                        </div>
                                        <!--end::Navigation-->
                                    </b-dropdown>
                                    <span @click.prevent="reload();"
                                        class="btn btn-default btn-icon btn-sm btn-light-primary mr-2"
                                        data-toggle="tooltip" title="Aggiorna la lista">
                                        <i class="ki ki-refresh icon-1x"></i>
                                    </span>
                                </div>
                                <div v-if="messagestotal" class="d-flex align-items-center mr-1 my-2">
                                    <span class="btn btn-default btn-icon btn-sm btn-light-primary mr-2"
                                        data-toggle="tooltip" title="Segna come letto"
                                        @click.prevent="markReadMultiple();">
                                        <span class="svg-icon svg-icon-md">
                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-opened.svg-->
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path
                                                        d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,12 C19,12.5522847 18.5522847,13 18,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M7.5,5 C7.22385763,5 7,5.22385763 7,5.5 C7,5.77614237 7.22385763,6 7.5,6 L13.5,6 C13.7761424,6 14,5.77614237 14,5.5 C14,5.22385763 13.7761424,5 13.5,5 L7.5,5 Z M7.5,7 C7.22385763,7 7,7.22385763 7,7.5 C7,7.77614237 7.22385763,8 7.5,8 L10.5,8 C10.7761424,8 11,7.77614237 11,7.5 C11,7.22385763 10.7761424,7 10.5,7 L7.5,7 Z"
                                                        fill="#000000" opacity="0.3" />
                                                    <path
                                                        d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z"
                                                        fill="#000000" />
                                                </g>
                                            </svg>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </span>
                                    <span class="btn btn-default btn-icon btn-sm btn-light-primary mr-2"
                                        data-toggle="tooltip" title="Segna come non letto"
                                        @click.prevent="markUnreadMultiple();">
                                        <span class="svg-icon svg-icon-md">
                                            <!--begin::Svg Icon | path:assets/media/svg/icons/General/Duplicate.svg-->
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path
                                                        d="M15.9956071,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,15.9956071 C4.70185442,15.9316381 4,15.1706419 4,13.8181818 L4,6.18181818 C4,4.76751186 4.76751186,4 6.18181818,4 L13.8181818,4 C15.1706419,4 15.9316381,4.70185442 15.9956071,6 Z"
                                                        fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                    <path
                                                        d="M10.1818182,8 L17.8181818,8 C19.2324881,8 20,8.76751186 20,10.1818182 L20,17.8181818 C20,19.2324881 19.2324881,20 17.8181818,20 L10.1818182,20 C8.76751186,20 8,19.2324881 8,17.8181818 L8,10.1818182 C8,8.76751186 8.76751186,8 10.1818182,8 Z"
                                                        fill="#000000" />
                                                </g>
                                            </svg>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </span>
                                    <span class="btn btn-default btn-icon btn-sm btn-light-success mr-2"
                                        data-toggle="tooltip" title="Ripristina" @click.prevent="restoreMultiple();">
                                        <span class="svg-icon svg-icon-md">
                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Repeat.svg-->
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path
                                                        d="M12,8 L8,8 C5.790861,8 4,9.790861 4,12 L4,13 C4,14.6568542 5.34314575,16 7,16 L7,18 C4.23857625,18 2,15.7614237 2,13 L2,12 C2,8.6862915 4.6862915,6 8,6 L12,6 L12,4.72799742 C12,4.62015048 12.0348702,4.51519416 12.0994077,4.42878885 C12.264656,4.2075478 12.5779675,4.16215674 12.7992086,4.32740507 L15.656242,6.46136716 C15.6951359,6.49041758 15.7295917,6.52497737 15.7585249,6.56395854 C15.9231063,6.78569617 15.876772,7.09886961 15.6550344,7.263451 L12.798001,9.3840407 C12.7118152,9.44801079 12.607332,9.48254921 12.5,9.48254921 C12.2238576,9.48254921 12,9.25869158 12,8.98254921 L12,8 Z"
                                                        fill="#000000" />
                                                    <path
                                                        d="M12.0583175,16 L16,16 C18.209139,16 20,14.209139 20,12 L20,11 C20,9.34314575 18.6568542,8 17,8 L17,6 C19.7614237,6 22,8.23857625 22,11 L22,12 C22,15.3137085 19.3137085,18 16,18 L12.0583175,18 L12.0583175,18.9825492 C12.0583175,19.2586916 11.8344599,19.4825492 11.5583175,19.4825492 C11.4509855,19.4825492 11.3465023,19.4480108 11.2603165,19.3840407 L8.40328311,17.263451 C8.18154548,17.0988696 8.13521119,16.7856962 8.29979258,16.5639585 C8.32872576,16.5249774 8.36318164,16.4904176 8.40207551,16.4613672 L11.2591089,14.3274051 C11.48035,14.1621567 11.7936615,14.2075478 11.9589099,14.4287888 C12.0234473,14.5151942 12.0583175,14.6201505 12.0583175,14.7279974 L12.0583175,16 Z"
                                                        fill="#000000" opacity="0.3" />
                                                </g>
                                            </svg><!--end::Svg Icon-->
                                        </span>
                                    </span>
                                    <span class="btn btn-default btn-icon btn-sm btn-light-danger mr-2"
                                        data-toggle="tooltip"
                                        :title="(filters.trashedflag === '1' ? 'Elimina definitivamente' : 'Metti nel Cestino')"
                                        @click.prevent="trashMultiple();">
                                        <span class="svg-icon svg-icon-md">
                                            <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path
                                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                        fill="#000000" fill-rule="nonzero" />
                                                    <path
                                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                        fill="#000000" opacity="0.3" />
                                                </g>
                                            </svg>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <!--end::Toolbar-->
                            <!--begin::Search-->
                            <div class="col-xxl-3 d-flex order-1 order-xxl-2 align-items-center justify-content-center">
                                <div class="input-group input-group-lg input-group-solid my-2">
                                    <input type="text" class="form-control pl-4" placeholder="Cerca..."
                                        v-model="filters.q" />
                                    <div class="input-group-append">
                                        <span class="input-group-text pr-3 cursor-pointer text-hover-primary">
                                            <span class="svg-icon svg-icon-lg">
                                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                    height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path
                                                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                            fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                        <path
                                                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                            fill="#000000" fill-rule="nonzero" />
                                                    </g>
                                                </svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!--end::Search-->
                            <!--begin::Pagination-->
                            <div
                                class="col-12 col-sm-6 col-xxl-4 order-2 order-xxl-3 d-flex align-items-center justify-content-sm-end text-right my-2">
                                <!--begin::Per Pagina Dropdown-->
                                <div class="d-flex align-items-center mr-2" data-toggle="tooltip"
                                    title="Messaggi per pagina">
                                    <span v-if="messagestotal" class="text-dark font-weight-bold mr-2"
                                        data-toggle="dropdown">{{ messagesfrom }}-{{
                                            messagesto }} di
                                        {{ messagestotal }}</span>
                                    <b-dropdown v-if="messagestotal" id="perpage-dropdown" size="sm" variant="link"
                                        toggle-class="custom-v-dropdown btn btn-default btn-icon btn-sm btn-light-primary mr-1 no-dropdown-icon"
                                        dropleft ref="perpageDropdown">
                                        <template v-slot:button-content>
                                            <i class="flaticon2-document icon-1x"></i>
                                        </template>
                                        <div v-for="(numperpage, index) in notificationsnumperpages" :key="index"
                                            class="navi navi-hover min-w-md-250px">
                                            <b-dropdown-text tag="div"
                                                :class="{ 'navi-item': numperpage.value !== perpage, 'navi-item-hover': numperpage.value === perpage }">
                                                <a href="#" @click.prevent="changeNumPerPage(numperpage)"
                                                    class="navi-link">
                                                    <i v-if="numperpage.value === perpage"
                                                        class="fas fa-check text-success mr-2"></i>
                                                    <span class="navi-text">{{ numperpage.label }}{{ (numperpage.label
                                                        === 'Tutti' ? '' : ' per
                                                        pagina' ) }}</span>
                                                </a>
                                            </b-dropdown-text>
                                        </div>
                                    </b-dropdown>
                                </div>
                                <!--end::Per Pagina Dropdown-->
                                <!--begin::Arrow Buttons-->
                                <span v-if="messagestotal && current_page > 1"
                                    class="btn btn-default btn-icon btn-sm btn-light-primary mr-1" data-toggle="tooltip"
                                    title="Prima pagina" @click.prevent="changePage(1);">
                                    <i class="ki ki-bold-double-arrow-back icon-sm"></i>
                                </span>
                                <span v-if="messagestotal && current_page === 1"
                                    class="btn btn-default btn-icon btn-sm btn-light-primary mr-1 disabled"
                                    data-toggle="tooltip" title="Prima pagina">
                                    <i class="ki ki-bold-double-arrow-back icon-sm"></i>
                                </span>
                                <span v-if="messagestotal && current_page > 1"
                                    class="btn btn-default btn-icon btn-sm btn-light-primary mr-1" data-toggle="tooltip"
                                    title="Pagina precedente" @click.prevent="changePage(current_page - 1);">
                                    <i class="ki ki-bold-arrow-back icon-sm"></i>
                                </span>
                                <span v-if="messagestotal && current_page === 1"
                                    class="btn btn-default btn-icon btn-sm btn-light-primary mr-1 disabled"
                                    data-toggle="tooltip" title="Pagina precedente">
                                    <i class="ki ki-bold-arrow-back icon-sm"></i>
                                </span>
                                <span v-if="messagestotal && current_page < last_page"
                                    class="btn btn-default btn-icon btn-sm btn-light-primary mr-1" data-toggle="tooltip"
                                    title="Pagina seguente" @click.prevent="changePage(current_page + 1);">
                                    <i class="ki ki-bold-arrow-next icon-sm"></i>
                                </span>
                                <span v-if="messagestotal && current_page === last_page"
                                    class="btn btn-default btn-icon btn-sm btn-light-primary mr-1 disabled"
                                    data-toggle="tooltip" title="Pagina seguente">
                                    <i class="ki ki-bold-arrow-next icon-sm"></i>
                                </span>
                                <span v-if="messagestotal && current_page < last_page"
                                    class="btn btn-default btn-icon btn-sm btn-light-primary mr-1" data-toggle="tooltip"
                                    title="Ultima pagina" @click.prevent="changePage(last_page);">
                                    <i class="ki ki-bold-double-arrow-next icon-sm"></i>
                                </span>
                                <span v-if="messagestotal && current_page === last_page"
                                    class="btn btn-default btn-icon btn-sm btn-light-primary mr-1 disabled"
                                    data-toggle="tooltip" title="Ultima pagina">
                                    <i class="ki ki-bold-double-arrow-next icon-sm"></i>
                                </span>
                                <!--end::Arrow Buttons-->
                                <!--begin::Sort Dropdown-->
                                <b-dropdown id="sort-dropdown" size="sm" variant="link"
                                    toggle-class="custom-v-dropdown btn btn-default btn-icon btn-sm btn-light-primary mr-1 no-dropdown-icon"
                                    dropleft ref="sortDropdown">
                                    <template v-slot:button-content>
                                        <i class="flaticon2-console icon-1x"></i>
                                    </template>
                                    <b-dropdown-text><strong>Filtra per</strong></b-dropdown-text>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-form>
                                        <b-form-checkbox v-model="filter_segreteria" value="1" class="mb-3">Segreteria
                                            Nazionale</b-form-checkbox>
                                        <b-form-checkbox v-model="filter_comitati" value="1"
                                            class="mb-3">Comitati</b-form-checkbox>
                                        <b-form-checkbox v-model="filter_sodalizi" value="1"
                                            class="mb-3">Sodalizi</b-form-checkbox>
                                    </b-dropdown-form>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-text v-if="messagestotal"><strong>Ordina per</strong></b-dropdown-text>
                                    <b-dropdown-divider v-if="messagestotal"></b-dropdown-divider>
                                    <div v-if="messagestotal" class="navi navi-hover min-w-md-250px">
                                        <b-dropdown-text tag="div"
                                            :class="{ 'navi-item': (this.sortBy.field !== 'date' || this.sortBy.direction !== 'DESC'), 'navi-item-hover': (this.sortBy.field === 'date' && this.sortBy.direction === 'DESC') }">
                                            <a href="#" class="navi-link" @click.prevent="sortFields('date', 'DESC');">
                                                <i v-if="this.sortBy.field === 'date' && this.sortBy.direction === 'DESC'"
                                                    class="fas fa-check text-success mr-2"></i>
                                                <span class="navi-text">Più Recenti</span>
                                            </a>
                                        </b-dropdown-text>
                                        <b-dropdown-text tag="div"
                                            :class="{ 'navi-item': (this.sortBy.field !== 'date' || this.sortBy.direction !== 'ASC'), 'navi-item-hover': (this.sortBy.field === 'date' && this.sortBy.direction === 'ASC') }">
                                            <a href="#" class="navi-link" @click.prevent="sortFields('date', 'ASC');">
                                                <i v-if="this.sortBy.field === 'date' && this.sortBy.direction === 'ASC'"
                                                    class="fas fa-check text-success mr-2"></i>
                                                <span class="navi-text">Meno Recenti</span>
                                            </a>
                                        </b-dropdown-text>
                                        <b-dropdown-text tag="div"
                                            :class="{ 'navi-item': (this.sortBy.field !== 'title' || this.sortBy.direction !== 'ASC'), 'navi-item-hover': (this.sortBy.field === 'title' && this.sortBy.direction === 'ASC') }">
                                            <a href="#" class="navi-link" @click.prevent="sortFields('title', 'ASC');">
                                                <i v-if="this.sortBy.field === 'title' && this.sortBy.direction === 'ASC'"
                                                    class="fas fa-check text-success mr-2"></i>
                                                <span class="navi-text">Titolo</span>
                                            </a>
                                        </b-dropdown-text>
                                        <b-dropdown-text tag="div"
                                            :class="{ 'navi-item': (this.sortBy.field !== 'surname' || this.sortBy.direction !== 'ASC'), 'navi-item-hover': (this.sortBy.field === 'surname' && this.sortBy.direction === 'ASC') }">
                                            <a href="#" class="navi-link"
                                                @click.prevent="sortFields('surname', 'ASC');">
                                                <i v-if="this.sortBy.field === 'surname' && this.sortBy.direction === 'ASC'"
                                                    class="fas fa-check text-success mr-2"></i>
                                                <span class="navi-text">Mittente</span>
                                            </a>
                                        </b-dropdown-text>
                                    </div>
                                </b-dropdown>
                                <!--end::Sort Dropdown-->
                            </div>
                            <!--end::Pagination-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div v-show="viewType === 'list'" class="card-body table-responsive text-justify p-0">
                            <!--begin::perfect-scrollbar-->
                            <perfect-scrollbar class="scroll" style="max-height: 70vh; position: relative;">
                                <SmartTable ref="notificationstable" url="/notifications/index" :is_dialog="false"
                                    base_url="/notifications" base_path="/" :fixed_filters="getFixedFilters"
                                    v-on:close-modal="closeModal">
                                    <template v-slot:header>
                                        &nbsp;
                                    </template>

                                    <template v-slot:body="slotProps">
                                        <!--begin::Items-->
                                        <div class="list min-w-500px">

                                            <div v-if="!slotProps.values.items.length" class="text-center text-dark-75">
                                                Nessun notifica da visualizzare</div>

                                            <!--begin::Item-->
                                            <div v-for="(item, index) in slotProps.values.items" :key="item.id"
                                                class="d-flex align-items-start list-item card-spacer-x py-3"
                                                :class="{ 'marked-read': (item.readflag === 'Si' ? true : false), 'marked-unread': (item.readflag === 'Si' ? false : true) }"
                                                data-inbox="message">
                                                <!--begin::Toolbar-->
                                                <div class="d-flex align-items-center">
                                                    <!--begin::Actions-->
                                                    <div class="d-flex align-items-center mr-3 mt-2"
                                                        data-inbox="actions">
                                                        <label
                                                            class="checkbox checkbox-inline checkbox-primary flex-shrink-0 mr-3">
                                                            <input type="checkbox" :value="item.id"
                                                                v-model="notificationsChecked[item.id]" />
                                                            <span></span>
                                                        </label>
                                                        <a href="#" class="btn btn-icon btn-xs" data-toggle="tooltip"
                                                            :class="{ 'text-hover-muted': item.specialflag === 'Si', 'text-hover-warning': (!item.specialflag || item.specialflag === 'No') }"
                                                            data-placement="right" title="Contrassegna come Speciale"
                                                            @click.prevent="toggleSpecial(item.id);">
                                                            <i class="flaticon-star"
                                                                :class="{ 'text-muted': (!item.specialflag || item.specialflag === 'No'), 'text-warning': item.specialflag === 'Si' }"></i>
                                                        </a>
                                                        <a href="#" class="btn btn-icon btn-xs"
                                                            :class="{ 'text-hover-muted': item.importantflag === 'Si', 'text-hover-warning': (!item.importantflag || item.importantflag === 'No') }"
                                                            data-toggle="tooltip" data-placement="right"
                                                            title="Contrassegna come importante"
                                                            @click.prevent="toggleImportant(item.id);">
                                                            <i class="flaticon-add-label-button"
                                                                :class="{ 'text-muted': (!item.importantflag || item.importantflag === 'No'), 'text-warning': item.importantflag === 'Si' }"></i>
                                                        </a>
                                                    </div>
                                                    <!--end::Actions-->
                                                    <!--begin::Author-->
                                                    <div @click.prevent="viewMessage(item);"
                                                        class="d-flex flex-column mr-3 mt-2">
                                                        <div class="d-flex align-items-center flex-wrap w-xxl-200px">
                                                            <a v-if="item.comitato" href="#"
                                                                @click.prevent="viewMessage(item);" class="text-dark-75"
                                                                :class="{ 'font-weight-bold': (item.readflag === 'Si' ? false : true), 'text-hover-primary': (item.readflag === 'Si' ? false : true) }">{{
                                                                    item.comitato }}</a>
                                                            <a v-if="item.sodalizio" href="#"
                                                                @click.prevent="viewMessage(item);" class="text-dark-75"
                                                                :class="{ 'font-weight-bold': (item.readflag === 'Si' ? false : true), 'text-hover-primary': (item.readflag === 'Si' ? false : true) }">{{
                                                                    item.sodalizio }}</a>
                                                            <a v-if="!item.comitato && !item.sodalizio" href="#"
                                                                @click.prevent="viewMessage(item);" class="text-dark-75"
                                                                :class="{ 'font-weight-bold': (item.readflag === 'Si' ? false : true), 'text-hover-primary': (item.readflag === 'Si' ? false : true) }">SEGRETERIA
                                                                NAZIONALE</a>
                                                        </div>
                                                        <div v-if="item.comitato || item.sodalizio" class="mt-2"
                                                            @click.prevent="viewMessage(item);">
                                                            <span v-if="item.comitato"
                                                                class="label label-light-primary font-weight-bold label-inline mr-1">Comitato</span>
                                                            <span v-if="item.sodalizio"
                                                                class="label label-light-success font-weight-bold label-inline mr-1">Sodalizio</span>
                                                        </div>
                                                    </div>
                                                    <!--end::Author-->
                                                </div>
                                                <!--end::Toolbar-->
                                                <!--begin::Info-->
                                                <div @click.prevent="viewMessage(item);" class="flex-grow-1 mt-2 mr-2">
                                                    <div>
                                                        <span class="font-size-lg mr-2"
                                                            :class="{ 'font-weight-bolder': (item.readflag === 'Si' ? false : true), 'text-hover-primary': (item.readflag === 'Si' ? false : true) }">{{
                                                                item.title }}
                                                            -</span>
                                                        <span class="text-dark-75"
                                                            :class="{ 'text-hover-primary': (item.readflag === 'Si' ? false : true) }">{{
                                                                html2text(item) }}...</span>
                                                    </div>
                                                    <div class="mt-2">
                                                        <span v-if="item.idmessagetype === 'Affiliazioni'"
                                                            class="label label-light-info font-weight-bold label-inline mr-1">Affiliazioni</span>
                                                        <span v-if="item.idmessagetype === 'Sodalizi'"
                                                            class="label label-light-info font-weight-bold label-inline mr-1">Sodalizi</span>
                                                        <span v-if="item.idmessagetype === 'Tesserati'"
                                                            class="label label-light-success font-weight-bold label-inline mr-1">Tesserati</span>
                                                        <span v-if="item.idmessagetype === 'Avvisi'"
                                                            class="label label-light-danger font-weight-bold label-inline mr-1">Avvisi</span>
                                                        <span v-if="item.idmessagetype === 'Credito Comitati'"
                                                            class="label label-light-warning font-weight-bold label-inline mr-1">Credito
                                                            Comitati</span>
                                                        <span v-if="item.idmessagetype === 'Documenti Sodalizi'"
                                                            class="label label-light-primary font-weight-bold label-inline mr-1">Documenti
                                                            Sodalizi</span>
                                                        <span v-if="item.idmessagetype === 'Certificati Sodalizi'"
                                                            class="label label-light-primary font-weight-bold label-inline mr-1">Certificati
                                                            Sodalizi</span>
                                                        <span v-if="item.idmessagetype === 'Attività'"
                                                            class="label label-light-info font-weight-bold label-inline mr-1">Attività</span>
                                                        <span v-if="item.idmessagetype === 'Documenti e Comunicazioni'"
                                                            class="label label-light-primary font-weight-bold label-inline mr-1">Comunicazioni
                                                            e Documenti utili</span>
                                                        <span v-if="item.specialflag === 'Si'"
                                                            class="label label-light-warning font-weight-bold label-inline mr-1">Speciale</span>
                                                        <span v-if="item.importantflag === 'Si'"
                                                            class="label label-light-warning font-weight-bold label-inline mr-1">Importante</span>
                                                    </div>
                                                </div>
                                                <!--end::Info-->
                                                <!--begin::Datetime-->
                                                <div @click.prevent="viewMessage(item);"
                                                    class="mt-2 mr-3 w-90px text-right text-nowrap"
                                                    :class="{ 'font-weight-bolder': (item.readflag === 'Si' ? false : true), 'text-hover-primary': (item.readflag === 'Si' ? false : true) }"
                                                    v-html="item.date.replace(' ', '<br>')"></div>
                                                <!--end::Datetime-->
                                            </div>
                                            <!--end::Item-->

                                        </div>
                                        <!--end::Items-->
                                    </template>
                                </SmartTable>
                            </perfect-scrollbar>
                            <!--end::perfect-scrollbar-->
                        </div>
                        <!--end::Body-->

                        <!--begin::Header-->
                        <div v-show="viewType === 'message'"
                            class="card-header align-items-center flex-wrap justify-content-between py-5 h-auto">
                            <!--begin::Left-->
                            <div class="d-flex align-items-center my-2">
                                <a href="#" class="btn btn-clean btn-icon btn-sm btn-light-primary mr-6"
                                    data-inbox="back" data-toggle="tooltip" title="Torna indietro"
                                    @click.prevent="goMessageBack();">
                                    <i class="flaticon2-left-arrow-1"></i>
                                </a>
                                <span @click.prevent="markUnread(currentMessage.id); goMessageBack();"
                                    class="btn btn-default btn-icon btn-sm btn-light-primary mr-2" data-toggle="tooltip"
                                    title="Contrassegna come letto">
                                    <span class="svg-icon svg-icon-md">
                                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Duplicate.svg-->
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                            viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path
                                                    d="M15.9956071,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,15.9956071 C4.70185442,15.9316381 4,15.1706419 4,13.8181818 L4,6.18181818 C4,4.76751186 4.76751186,4 6.18181818,4 L13.8181818,4 C15.1706419,4 15.9316381,4.70185442 15.9956071,6 Z"
                                                    fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                <path
                                                    d="M10.1818182,8 L17.8181818,8 C19.2324881,8 20,8.76751186 20,10.1818182 L20,17.8181818 C20,19.2324881 19.2324881,20 17.8181818,20 L10.1818182,20 C8.76751186,20 8,19.2324881 8,17.8181818 L8,10.1818182 C8,8.76751186 8.76751186,8 10.1818182,8 Z"
                                                    fill="#000000"></path>
                                            </g>
                                        </svg>
                                        <!--end::Svg Icon-->
                                    </span>
                                </span>
                                <span class="btn btn-default btn-icon btn-sm btn-light-danger mr-2"
                                    data-toggle="tooltip" title="Metti nel Cestino"
                                    @click.prevent="trash(currentMessage.id); goMessageBack();">
                                    <span class="svg-icon svg-icon-md">
                                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                            viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path
                                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                    fill="#000000" fill-rule="nonzero"></path>
                                                <path
                                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                    fill="#000000" opacity="0.3"></path>
                                            </g>
                                        </svg>
                                        <!--end::Svg Icon-->
                                    </span>
                                </span>
                            </div>
                            <!--end::Left-->
                            <!--begin::Right-->
                            <div class="d-flex align-items-center justify-content-end text-right my-2">
                                <span class="btn btn-clean btn-sm btn-icon mr-2"
                                    :class="{ 'text-hover-muted': currentMessage.specialflag === 'Si', 'text-hover-warning': (!currentMessage.specialflag || currentMessage.specialflag === 'No') }"
                                    data-toggle="tooltip" data-placement="left" title="Contrassegna come speciale"
                                    @click.prevent="toggleSpecial(currentMessage.id);">
                                    <i class="flaticon-star icon-1x"
                                        :class="{ 'text-muted': (!currentMessage.specialflag || currentMessage.specialflag === 'No'), 'text-warning': currentMessage.specialflag === 'Si' }"></i>
                                </span>
                                <span class="btn btn-clean btn-sm btn-icon"
                                    :class="{ 'text-hover-muted': currentMessage.importantflag === 'Si', 'text-hover-warning': (!currentMessage.importantflag || currentMessage.importantflag === 'No') }"
                                    data-toggle="tooltip" data-placement="left" title="Contrassegna come importante"
                                    @click.prevent="toggleImportant(currentMessage.id);">
                                    <i class="flaticon-add-label-button icon-1x"
                                        :class="{ 'text-muted': (!currentMessage.importantflag || currentMessage.importantflag === 'No'), 'text-warning': currentMessage.importantflag === 'Si' }"></i>
                                </span>
                            </div>
                            <!--end::Right-->
                        </div>
                        <!--begin::Header-->

                        <!--begin::Body-->
                        <div v-show="viewType === 'message'" class="card-body p-0">
                            <!--begin::Header-->
                            <div class="d-flex align-items-center justify-content-between flex-wrap card-spacer-x py-2">
                                <!--begin::Title-->
                                <div class="d-flex align-items-center mr-2 py-1">
                                    <div class="font-weight-bold font-size-h3 mr-3">{{ currentMessage.title }}</div>
                                    <span v-if="currentMessage.idmessagetype === 'Affiliazioni'"
                                        class="label label-light-info font-weight-bold label-inline mr-2">Affiliazioni</span>
                                    <span v-if="currentMessage.idmessagetype === 'Sodalizi'"
                                        class="label label-light-info font-weight-bold label-inline mr-2">Sodalizi</span>
                                    <span v-if="currentMessage.idmessagetype === 'Tesserati'"
                                        class="label label-light-success font-weight-bold label-inline mr-2">Tesserati</span>
                                    <span v-if="currentMessage.idmessagetype === 'Avvisi'"
                                        class="label label-light-danger font-weight-bold label-inline mr-2">Avvisi</span>
                                    <span v-if="currentMessage.idmessagetype === 'Credito Comitati'"
                                        class="label label-light-warning font-weight-bold label-inline mr-2">Credito
                                        Comitati</span>
                                    <span v-if="currentMessage.idmessagetype === 'Documenti Sodalizi'"
                                        class="label label-light-primary font-weight-bold label-inline mr-2">Documenti
                                        Sodalizi</span>
                                    <span v-if="currentMessage.idmessagetype === 'Certificati Sodalizi'"
                                        class="label label-light-primary font-weight-bold label-inline mr-2">Certificati
                                        Sodalizi</span>
                                    <span v-if="currentMessage.idmessagetype === 'Attività'"
                                        class="label label-light-info font-weight-bold label-inline mr-2">Attività</span>
                                    <span v-if="currentMessage.idmessagetype === 'Documenti e Comunicazioni'"
                                        class="label label-light-primary font-weight-bold label-inline mr-2">Comunicazioni
                                        e
                                        Documenti utili</span>
                                    <span v-if="currentMessage.specialflag === 'Si'"
                                        class="label label-light-warning font-weight-bold label-inline mr-2">Speciale</span>
                                    <span v-if="currentMessage.importantflag === 'Si'"
                                        class="label label-light-warning font-weight-bold label-inline mr-2">Importante</span>
                                </div>
                                <!--end::Title-->
                                <!--begin::Toolbar-->
                                <div class="d-flex py-1">
                                    <div class="font-weight-bold text-dark-75">{{ currentMessage.date }}</div>
                                </div>
                                <!--end::Toolbar-->
                            </div>
                            <!--end::Header-->
                            <!--begin::Messages-->
                            <div class="mb-3">
                                <div class="cursor-pointer shadow-xs" data-inbox="message">
                                    <div class="d-flex align-items-center card-spacer-x py-2">
                                        <div class="d-flex flex-column flex-grow-1 flex-wrap align-items-end mr-4">
                                        </div>
                                    </div>
                                    <div class="card-spacer-x py-3" v-if="currentMessage.idmessagetype !== 'Avvisi'">{{
                                        currentMessage.body }}
                                    </div>
                                    <div class="card-spacer-x py-3" v-if="currentMessage.idmessagetype === 'Avvisi'"
                                        v-html="currentMessage.body"></div>
                                </div>
                            </div>
                            <!--end::Messages-->
                        </div>
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Aside-->
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SmartTable from "@/view/components/SmartTable.vue";
import ApiService from "@/core/services/api.service";

export default {
    name: "notifications",

    data() {
        return {
            notificationsChecked: {},
            notificationsCheckedType: '',
            notificationsCheckedWatch: true,
            numchecked: 0,
            mainChecked: false,

            notificationsCheckedTypes: ['Tutti', 'Nessuno', 'Letti', 'Non Letti', 'Speciali', 'Importanti', 'Normali'],

            unreadNotifications: {},

            viewType: 'list',

            currentMessage: {
                idmessage: '',
                title: '',
                body: '',
                date: '',
            },

            messagesfrom: 0,
            messagesto: 0,
            messagestotal: 0,

            current_page: 1,
            last_page: null,

            perpage: 15,
            sortBy: { field: 'date', direction: 'DESC' },

            notificationsnumperpages: [],

            filters: {
                idmessagetype: '',
                q: '',
                specialflag: '',
                importantflag: '',
                readflag: '',
                trashedflag: '0',
                idusertype: '',
            },

            filter_segreteria: 0,
            filter_comitati: 0,
            filter_sodalizi: 0,
        }
    },

    components: {
        SmartTable,
    },

    computed: {
        getFixedFilters() {
            let filters = [];
            if (typeof this.filters !== "undefined" && this.filters !== null) {
                if (typeof this.filters.idmessagetype !== "undefined" && this.filters.idmessagetype && this.filters.idmessagetype instanceof Array) {
                    for (let i in this.filters.idmessagetype) {
                        filters.push({ 'name': 'idmessagetype[]', 'filter': this.filters.idmessagetype[i] });
                    }
                }
                else if (this.filters.idmessagetype) {
                    filters.push({ 'name': 'idmessagetype[]', 'filter': this.filters.idmessagetype });
                }

                if (typeof this.filters.idusertype !== "undefined" && this.filters.idusertype && this.filters.idusertype.length > 0) {
                    for (let i in this.filters.idusertype) {
                        filters.push({ 'name': 'idusertype[]', 'filter': this.filters.idusertype[i] });
                    }
                }

                if (typeof this.filters.q !== "undefined" && this.filters.q.length > 0) {
                    filters.push({ 'name': 'q', 'filter': this.filters.q });
                }

                if (typeof this.filters.specialflag !== "undefined" && this.filters.specialflag.length > 0) {
                    filters.push({ 'name': 'specialflag', 'filter': this.filters.specialflag });
                }

                if (typeof this.filters.importantflag !== "undefined" && this.filters.importantflag.length > 0) {
                    filters.push({ 'name': 'importantflag', 'filter': this.filters.importantflag });
                }

                if (typeof this.filters.readflag !== "undefined" && this.filters.readflag.length > 0) {
                    filters.push({ 'name': 'readflag', 'filter': this.filters.readflag });
                }

                if (typeof this.filters.trashedflag !== "undefined" && this.filters.trashedflag.length > 0) {
                    filters.push({ 'name': 'trashedflag', 'filter': this.filters.trashedflag });
                }

                return filters;
            }

            return null;
        }
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Notifiche" }
        ]);

        this.mountWatches();

        this.loadUnreadNotifications();
    },

    watch: {
        filter_segreteria() {
            this.filter_from();
        },

        filter_comitati() {
            this.filter_from();
        },

        filter_sodalizi() {
            this.filter_from();
        },

        filters: {
            handler: function (val, oldVal) {
                let vm = this;

                setTimeout(function () {
                    if (typeof vm.$refs.notificationstable !== 'undefined') vm.$refs.notificationstable.refresh();
                }, 100);
            },
            deep: true,
        },

        notificationsChecked: {
            handler: function () {
                if (this.notificationsCheckedWatch) {
                    this.notificationsCheckedType = '';

                    this.numchecked = 0;
                    for (let index in this.$refs.notificationstable.items) {
                        if (typeof this.notificationsChecked[this.$refs.notificationstable.items[index].id] !== "undefined" && this.notificationsChecked[this.$refs.notificationstable.items[index].id] === true) {
                            this.numchecked++;
                        }
                    }

                    this.mainChecked = (this.numchecked > 0 && this.numchecked === this.$refs.notificationstable.items.length);

                    if (this.mainChecked) {
                        this.notificationsCheckedType = 'Tutti';
                    }
                }
                else {
                    this.notificationsCheckedWatch = true;
                }
            },
            deep: true,
        },
    },

    methods: {
        filter_from() {
            this.filters.idusertype = [];

            if (this.filter_segreteria === '1') {
                this.filters.idusertype.push('1');
                this.filters.idusertype.push('2');
            }

            if (this.filter_comitati === '1') {
                this.filters.idusertype.push('4');
            }

            if (this.filter_sodalizi === '1') {
                this.filters.idusertype.push('5');
            }
        },

        loadUnreadNotifications() {
            let url = '/notifications/load-unread-notifications-count';
            ApiService.query(url)
                .then((response) => {
                    if (response.data.status === 'OK') {
                        for (let i in this.unreadNotifications) {
                            this.$set(this.unreadNotifications, i, 0);
                        }

                        let messages = response.data.messages;

                        let countInbox = 0;
                        let countTrashed = 0;
                        let countSpecial = 0;
                        let countImportant = 0;
                        for (let i in messages) {
                            if (messages[i].trashedflag) {
                                countTrashed += messages[i].num_messages;
                            }
                            else {
                                countInbox += messages[i].num_messages;
                            }

                            if (!messages[i].trashedflag && messages[i].specialflag) {
                                countSpecial += messages[i].num_messages;
                            }

                            if (!messages[i].trashedflag && messages[i].importantflag) {
                                countImportant += messages[i].num_messages;
                            }

                            if (!messages[i].trashedflag && typeof this.unreadNotifications[messages[i].messagetype] === 'undefined') {
                                this.$set(this.unreadNotifications, messages[i].messagetype, 0);
                            }

                            if (!messages[i].trashedflag) this.$set(this.unreadNotifications, messages[i].messagetype, this.unreadNotifications[messages[i].messagetype] + messages[i].num_messages);
                        }

                        if (countInbox) {
                            if (typeof this.unreadNotifications['Inbox'] !== 'undefined') {
                                this.$set(this.unreadNotifications, 'Inbox', 0);
                            }

                            this.$set(this.unreadNotifications, 'Inbox', countInbox);
                        }

                        if (countTrashed) {
                            if (typeof this.unreadNotifications['Trashed'] !== 'undefined') {
                                this.$set(this.unreadNotifications, 'Trashed', 0);
                            }

                            this.$set(this.unreadNotifications, 'Trashed', countTrashed);
                        }

                        if (countSpecial) {
                            if (typeof this.unreadNotifications['Speciali'] !== 'undefined') {
                                this.$set(this.unreadNotifications, 'Speciali', 0);
                            }

                            this.$set(this.unreadNotifications, 'Speciali', countSpecial);
                        }

                        if (countImportant) {
                            if (typeof this.unreadNotifications['Importanti'] !== 'undefined') {
                                this.$set(this.unreadNotifications, 'Importanti', 0);
                            }

                            this.$set(this.unreadNotifications, 'Importanti', countImportant);
                        }
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);

                    this.loading = false;
                });
        },

        viewMessage(item) {
            this.currentMessage = JSON.parse(JSON.stringify(item));

            this.viewType = 'message';

            if (typeof this.currentMessage !== 'undefined' && typeof this.currentMessage.id !== 'undefined') this.markRead(this.currentMessage.id);
        },

        goMessageBack() {
            this.viewType = 'list';

            let vm = this;
            setTimeout(function () {
                if (typeof vm.$refs.notificationstable !== 'undefined') vm.$refs.notificationstable.reload();
            }, 100);
        },

        messageAction(actionurl, id) {
            this.loading = true;

            let baseurl = '/notifications';

            let url = baseurl + '/' + actionurl + '/' + id;
            ApiService.query(url)
                .then((response) => {
                    if (response.data.status === 'OK') {
                        this.reload();
                        this.loadUnreadNotifications();
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);

                    this.loading = false;
                });
        },

        messageActionMultiple(actionurl) {
            let ids = [];

            if (typeof this.notificationsChecked !== "undefined") {
                for (let i in this.notificationsChecked) {
                    if (this.notificationsChecked[i] === true) {
                        ids.push(i);
                    }
                }
            }

            if (ids.length <= 0) {
                return;
            }

            this.loading = true;

            let baseurl = '/notifications';

            let url = baseurl + '/' + actionurl;
            ApiService.post(url, { ids: ids })
                .then((response) => {
                    if (response.data.status === 'OK') {
                        this.reload();

                        this.loadUnreadNotifications();
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);

                    this.loading = false;
                });
        },

        toggleSpecial(id) {
            this.messageAction('toggle-special', id);

            if (typeof this.currentMessage !== 'undefined' && typeof this.currentMessage.id !== 'undefined' && this.currentMessage.id === id) {
                if (this.currentMessage.specialflag === 'Si') this.currentMessage.specialflag = 'No';
                else if (this.currentMessage.specialflag === 'No') this.currentMessage.specialflag = 'Si';
            }
        },

        toggleImportant(id) {
            this.messageAction('toggle-important', id);

            if (typeof this.currentMessage !== 'undefined' && typeof this.currentMessage.id !== 'undefined' && this.currentMessage.id === id) {
                if (this.currentMessage.importantflag === 'Si') this.currentMessage.importantflag = 'No';
                else if (this.currentMessage.importantflag === 'No') this.currentMessage.importantflag = 'Si';
            }
        },

        markRead(id) {
            this.messageAction('mark-read', id);
        },

        markUnread(id) {
            this.messageAction('mark-unread', id);
        },

        trash(id) {
            this.messageAction('trash', id);
        },

        restore(id) {
            this.messageAction('restore', id);
        },

        toggleSpecialMultiple() {
            this.messageActionMultiple('toggle-special');
        },

        toggleImportantMultiple() {
            this.messageActionMultiple('toggle-important');
        },

        markReadMultiple() {
            this.messageActionMultiple('mark-read');
        },

        markUnreadMultiple() {
            this.messageActionMultiple('mark-unread');
        },

        trashMultiple() {
            if (this.filters.trashedflag === '1') {
                this.messageActionMultiple('delete');
            }
            else {
                this.messageActionMultiple('trash');
            }
        },

        restoreMultiple() {
            this.messageActionMultiple('restore');
        },

        changePage(page) {
            this.$refs.notificationstable.current_page = page;

            this.$refs.notificationstable.reload();
        },

        toggleNotificationsCheckbox(id) {
            this.notificationsCheckedWatch = false;

            this.notificationsCheckedType = '';

            if (typeof this.notificationsChecked[id] === "undefined") {
                this.notificationsChecked[id] = true;
            }
            else {
                this.notificationsChecked[id] = !this.notificationsChecked[id];
            }

            this.numchecked = 0;
            for (let index in this.$refs.notificationstable.items) {
                if (typeof this.notificationsChecked[index] !== "undefined" && this.notificationsChecked[index] === true) {
                    this.numchecked++;
                }
            }

            this.mainChecked = (this.numchecked > 0 && this.numchecked === this.$refs.notificationstable.items.length);

            if (this.mainChecked) {
                this.notificationsCheckedType = 'Tutti';
            }
        },

        selectNotifications(type) {
            this.notificationsCheckedWatch = false;

            this.numchecked = 0;
            for (let i in this.notificationsChecked) {
                this.notificationsChecked[i] = false;
            }

            this.notificationsCheckedType = type;

            if (typeof this.$refs.notificationstable === "undefined") {
                if (this.viewType === 'message') {
                    if (typeof this.currentMessage !== 'undefined' && typeof this.currentMessage.id !== 'undefined') this.markRead(this.currentMessage.id);

                    this.goMessageBack();
                }

                return;
            }

            switch (type) {
                case 'Tutti':
                    for (let i in this.$refs.notificationstable.items) {
                        this.$set(this.notificationsChecked, this.$refs.notificationstable.items[i].id, true);
                        this.numchecked++;
                    }
                    break;

                case 'Nessuno':
                    for (let i in this.$refs.notificationstable.items) {
                        this.$set(this.notificationsChecked, this.$refs.notificationstable.items[i].id, false);
                    }
                    break;

                case 'Letti':
                    for (let i in this.$refs.notificationstable.items) {
                        if (this.$refs.notificationstable.items[i].readflag === 'Si') {
                            this.$set(this.notificationsChecked, this.$refs.notificationstable.items[i].id, true);
                            this.numchecked++;
                        }
                    }
                    break;

                case 'Non Letti':
                    for (let i in this.$refs.notificationstable.items) {
                        if (this.$refs.notificationstable.items[i].readflag === 'No') {
                            this.$set(this.notificationsChecked, this.$refs.notificationstable.items[i].id, true);
                            this.numchecked++;
                        }
                    }
                    break;

                case 'Speciali':
                    for (let i in this.$refs.notificationstable.items) {
                        if (this.$refs.notificationstable.items[i].specialflag === 'Si') {
                            this.$set(this.notificationsChecked, this.$refs.notificationstable.items[i].id, true);
                            this.numchecked++;
                        }
                    }
                    break;

                case 'Importanti':
                    for (let i in this.$refs.notificationstable.items) {
                        if (this.$refs.notificationstable.items[i].importantflag === 'Si') {
                            this.$set(this.notificationsChecked, this.$refs.notificationstable.items[i].id, true);
                            this.numchecked++;
                        }
                    }
                    break;

                case 'Normali':
                    for (let i in this.$refs.notificationstable.items) {
                        if (this.$refs.notificationstable.items[i].specialflag === 'No' && this.$refs.notificationstable.items[i].importantflag === 'No') {
                            this.$set(this.notificationsChecked, this.$refs.notificationstable.items[i].id, true);
                            this.numchecked++;
                        }
                    }
                    break;
            }

            this.mainChecked = (this.numchecked > 0 && this.numchecked === this.$refs.notificationstable.items.length);
        },

        toggleMainChecked() {
            let type = '';

            if (this.mainChecked) {
                type = 'Nessuno';
            }

            if (!this.mainChecked) {
                type = 'Tutti';
            }
            this.selectNotifications(type);
        },

        sortFields(field, direction) {
            this.sortBy = { field: field, direction: direction };
            this.$refs.sortDropdown.hide(true);

            for (let i in this.$refs.notificationstable.fields) {
                this.$set(this.$refs.notificationstable.fields[i], 'ordering', false);

                if ((this.$refs.notificationstable.fields[i].name === field) && (this.$refs.notificationstable.fields[i].sortable)) {
                    this.$set(this.$refs.notificationstable.fields[i], 'ordering', true);

                    this.$refs.notificationstable.sortBy = field;
                    this.$refs.notificationstable.sortDir = direction;

                    this.$set(this.$refs.notificationstable.fields[i], 'ordering_direction', this.$refs.notificationstable.sortDir);
                }
                else {
                    this.$set(this.$refs.notificationstable.fields[i], 'ordering_direction', '');
                }
            }

            this.reload();
        },

        mountWatches() {
            this.$watch(
                () => {
                    if (typeof this.$refs.notificationstable !== "undefined" && this.$refs.notificationstable.from !== null) {
                        return this.$refs.notificationstable.from;
                    }

                    return 0;
                },
                (value) => {
                    this.messagesfrom = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.notificationstable !== "undefined" && this.$refs.notificationstable.to !== null) {
                        return this.$refs.notificationstable.to;
                    }

                    return 0;
                },
                (value) => {
                    this.messagesto = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.notificationstable !== "undefined" && this.$refs.notificationstable.total !== null) {
                        return this.$refs.notificationstable.total;
                    }

                    return 0;
                },
                (value) => {
                    this.messagestotal = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.notificationstable !== "undefined" && this.$refs.notificationstable.current_page !== null) {
                        return this.$refs.notificationstable.current_page;
                    }

                    return 0;
                },
                (value) => {
                    this.current_page = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.notificationstable !== "undefined" && this.$refs.notificationstable.last_page !== null) {
                        return this.$refs.notificationstable.last_page;
                    }

                    return 0;
                },
                (value) => {
                    this.last_page = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.notificationstable !== "undefined" && this.$refs.notificationstable.num_per_page !== null) {
                        return this.$refs.notificationstable.num_per_page;
                    }

                    return [];
                },
                (value) => {
                    this.notificationsnumperpages = JSON.parse(JSON.stringify(value));
                }
            );
        },

        changeNumPerPage(numperpage) {
            this.perpage = numperpage.value;
            this.$refs.perpageDropdown.hide(true);
            this.$refs.notificationstable.per_page = numperpage;
            this.$refs.notificationstable.reload();
        },

        reload() {
            if (typeof this.$refs.notificationstable !== 'undefined') this.$refs.notificationstable.reload();
        },

        goBack() {
            window.history.go(-1);
        },

        closeModal() {
            this.$refs.notificationstable.refresh();
        },

        toggleFilters(type) {
            this.selectNotifications('Nessuno');

            this.notificationsCheckedType = '';

            if (type === 'Inbox') {
                this.filters.idmessagetype = '';
                this.filters.trashedflag = '0';
                this.filters.importantflag = '';
                this.filters.specialflag = '';
            }

            if (type === 'Trashed') {
                this.filters.idmessagetype = '';
                this.filters.trashedflag = '1';
                this.filters.importantflag = '';
                this.filters.specialflag = '';
            }

            if (type === 'Special') {
                this.filters.idmessagetype = '';
                this.filters.trashedflag = '0';
                this.filters.importantflag = '';
                this.filters.specialflag = '1';
            }

            if (type === 'Important') {
                this.filters.idmessagetype = '';
                this.filters.trashedflag = '0';
                this.filters.importantflag = '1';
                this.filters.specialflag = '';
            }

            if (type === 'Avvisi') {
                this.filters.idmessagetype = '1';
                this.filters.trashedflag = '0';
                this.filters.importantflag = '';
                this.filters.specialflag = '';
            }

            if (type === 'Affiliazioni') {
                this.filters.idmessagetype = '2';
                this.filters.trashedflag = '0';
                this.filters.importantflag = '';
                this.filters.specialflag = '';
            }

            if (type === 'Tesserati') {
                this.filters.idmessagetype = '3';
                this.filters.trashedflag = '0';
                this.filters.importantflag = '';
                this.filters.specialflag = '';
            }

            if (type === 'Sodalizi') {
                this.filters.idmessagetype = ['4', '8', '10', '11'];
                this.filters.trashedflag = '0';
                this.filters.importantflag = '';
                this.filters.specialflag = '';
            }

            if (type === 'Credito Comitati') {
                this.filters.idmessagetype = '5';
                this.filters.trashedflag = '0';
                this.filters.importantflag = '';
                this.filters.specialflag = '';
            }

            if (type === 'Documenti Sodalizi') {
                this.filters.idmessagetype = '6';
                this.filters.trashedflag = '0';
                this.filters.importantflag = '';
                this.filters.specialflag = '';
            }

            if (type === 'Certificati Sodalizi') {
                this.filters.idmessagetype = '7';
                this.filters.trashedflag = '0';
                this.filters.importantflag = '';
                this.filters.specialflag = '';
            }

            if (type === 'Attività') {
                this.filters.idmessagetype = '9';
                this.filters.trashedflag = '0';
                this.filters.importantflag = '';
                this.filters.specialflag = '';
            }

            if (type === 'Documenti e Comunicazioni') {
                this.filters.idmessagetype = '12';
                this.filters.trashedflag = '0';
                this.filters.importantflag = '';
                this.filters.specialflag = '';
            }

            if (this.viewType === 'message') {
                this.viewType = 'list';
            }
        },

        html2text(item) {
            let text = '';

            if (item.idmessagetype === 'Avvisi') {
                let html = item.body;
                let div = document.createElement("div");
                div.innerHTML = html;

                text = div.textContent || div.innerText || "";
            }
            else {
                text = item.body;
            }

            return text.substring(0, 50) + '...';
        },
    },
};
</script>

<style>
.btn-group.dropleft>.btn.dropdown-toggle:before {
    display: none;
}

.btn-group.dropright>.btn.dropdown-toggle:before {
    display: none;
}

.dropdown-toggle.nav-link:after,
.dropdown-toggle.btn:after {
    display: none;
}

.card-header .checkbox>span {
    background-color: #FFFFFF;
    border: 2px solid #0066CC;
}

.list-item {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.list-item:hover {
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
    z-index: 2;
    cursor: pointer;
}

.list-item:hover {
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
    z-index: 2;
    cursor: pointer;
}

.list-item.marked-read {
    background-color: #F2F6FC;
}

.list-item.marked-unread {
    background-color: #FFFFFF;
}
</style>
